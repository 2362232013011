import React, { lazy, Suspense } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Loader from "react-spinners/HashLoader";
import history from "./history";

const Body = lazy(() =>
    import(/*webpackChunkName: "body" */ "./loanForm")
);
const Admin = lazy(() =>
    import(/*webpackChunkName: "body" */ "./Pages/Admin.js")
);

const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});
function App() {
    return (
        <Router history={history}>
            <Suspense fallback={<LoaderScreen />}>
                <Switch>
                    <Route exact path={"/"} component={Body} />
                    <Route exact path={"/admin"} component={Admin} />
                </Switch>
            </Suspense>
        </Router>
    );
}

const LoaderScreen = () => {
    const classes = useStyles();

    return (
        <Grid
            container
            className={classes.container}
            style={{
                marginTop: "15%",
                marginBottom: "15%",
            }}
        >
            <Loader size={60} color={"#123abc"} loading={true} />
        </Grid>
    );
};

export default App;
